import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataCandy } from 'src/provider/datacandy.provider';
import { Constants } from 'src/provider/constants';
import { HttpProvider } from 'src/provider/http.provider';

import { Events } from 'src/provider/events';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers:[DataCandy,Constants,HttpProvider,Events]
})
export class SharedModule {

    test:any = "33333";

 }
