import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataCandy } from 'src/provider/datacandy.provider';
import { HttpMethod, HttpProvider } from 'src/provider/http.provider';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  loading = false;

  constructor(private formBuilder: FormBuilder,private httpProvider:HttpProvider,private dataCandy:DataCandy,public router:Router) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      mobile: ['', Validators.required],
      password: ['', Validators.required],
  });

  }

  onSubmit(){
    if (this.form.invalid) {
      this.dataCandy.openSnackBar('Invalid mobile number and password');
      return;
    }
    this.loading = true;

    let input: any = {};
    input.relativeUrl = 'user/login';
    input.method = HttpMethod.POST;
    input.body = {"mobileno":this.f.mobile.value,"pass":this.f.password.value};
    this.httpProvider.apiCall(input).subscribe((res: any) => {
      
      this.dataCandy.loginCredentials = res;
      this.dataCandy.loggedInUserId = res.user_id;
      localStorage.setItem("loginCredentials",btoa(JSON.stringify(res)));
      this.dataCandy.loggedin = 1;
      this.router.navigate(['admin','dashboard']);
    }, (err: any) => {
      this.dataCandy.printLog(err);
      this.loading = false;

      this.dataCandy.openSnackBar('Invalid Credentials');
    })
  }

  get f() { return this.form.controls; }

}
