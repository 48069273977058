import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/provider/constants';
import { Router } from '@angular/router';
import { Events } from 'src/provider/events';

@Injectable()
export class DataCandy {
    showLoading: boolean;
    logs: any =[];
    userData: any;
    active:any=0;
    loginCredentials: any;
    loggedInUserId: any;
    loggedin: number;
    cat_images :any= {
        'medicine': 'medicine.png',
        'ayurvedic': 'ayurvedic.png',
        'beauty': 'beauty_cosmetics.png',
        'mother': 'baby_mother.png',
        'surgical': 'surgical.png',
        'food': 'food_products.png',
        'home': 'home_needs.png',
        'personal': 'personal_hygiene.png',
        'diet': 'diet_fitness.png',
    }
    categories: any = [];
    specialities: any = [];



    constructor(public events: Events, public constants: Constants, public router: Router) {

    }

    getHeader(key: any) {
        if (key.indexOf('_') >= 0) {
            let strarr = key.split('_');
            let str = strarr[0] + ' ' + strarr[1];
            return str;
        } else {
            return key;
        }

    }

    sort_by_key(array, key) {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }


    printLog(value: any, key: string = null) {
        
        this.logs.push({
            key: (new Date(Date.now()).toLocaleString()) + ":" + key,
            log: JSON.stringify(value)
        })
        if (this.logs.length > 300) {
            this.logs.shift();
        }
        console.log(this.logs);
    }



    showloader() {
        this.showLoading = true;
    }

    hideLoader() {
        this.showLoading = false;
    }

    jsonToQueryString(json) {
        return '?' +
            Object.keys(json).map(function (key) {
                return encodeURIComponent(key) + '=' +
                    encodeURIComponent(json[key]);
            }).join('&');
    }


  
    getProductImage(product) {

        if (!product.image) {
            return this.constants.FILEPATH + '/uploads/category/products/' + this.cat_images[product.category];
        }
        if(product.image.indexOf('/uploads/products/')>=0)
            return this.constants.FILEPATH + product.image;

        return this.constants.FILEPATH + '/uploads/products/' + product.image;

    }

    openSnackBar(message: string, action: string = null, option = null) {
            alert(message);
    }




    getBannerUrl(banner) {
        var img = this.constants.FILEPATH + "/uploads/advertisements/" + banner;

        if (banner.indexOf("uploads/advertisements") >= 0) {
            var img = this.constants.FILEPATH + "/" + banner;
        }

        return encodeURI(img);

    }

    getSpecialtyImage(specialty) {
        var img =
            this.constants.FILEPATH + "/uploads/specialities/" + specialty.image;

        if (specialty.image.indexOf("uploads/specialities") >= 0) {
            var img = this.constants.FILEPATH + "/" + specialty.image;
        }
        return encodeURI(img);
    }

    getCategoryImage(category) {
        var img = "";

        img = this.constants.FILEPATH + "/" + category.image;
        return img;
    }

    getDoctorName(doctor) {
        return doctor.firstname;
    }

    getBackgroundFilePath(filepath) {
        return "url(" + this.constants.FILEPATH + filepath + ")";
    }

    updateAds(ads) {
        let advertisements = [];

        ads.forEach((ad) => {
            console.log(ad);
            let arr = [];
            if (ad.images.indexOf(",") > 0) {
                let imgs = ad.images.split(",");
                arr = imgs;
            } else {
                arr.push(ad.images);
            }

            console.log("arr", arr);

            let urlarr = [];
            if (ad.urls.indexOf(",") > 0) {
                let urls = ad.urls.split(",");
                urlarr = urls;
            } else {
                urlarr.push(ad.urls);
            }

            advertisements.push({ images: arr, urls: urlarr });
        });

        return advertisements;
    }

    


    getDoctorImage(doctor) {
        if (doctor.profile_img) {
            return (
                this.constants.FILEPATH + doctor.profile_img
            );
        }
        return '../../assets/doctoravatar.svg';
    }

    navigateTo(url) {
        this.router.navigate([url]);
    }



    getLocalStorageItem(name) {
        return localStorage.getItem(name)
    }



    iOS() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    }

    Android() {
        return navigator.userAgent.match(/Android/i);
    }


    isMobile() {
        return /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }

    getProfileImage(img) {
        if (img) {
            return (
                this.constants.FILEPATH + img
            );
        }
        return '../../assets/doctoravatar.svg';
    }

    getLogoImage(img) {
        if (img) {
            return (
                this.constants.FILEPATH + img
            );
        }
        return '../../assets/doctoravatar.svg';
    }

    getSignatureImage(img) {
        if (img) {
            return (
                this.constants.FILEPATH + img
            );
        }
        return '../../assets/doctoravatar.svg';
    }

    getCertificateImage(img) {
        if (img) {
            return (
                this.constants.FILEPATH + img
            );
        }
        return '../../assets/doctoravatar.svg';
    }




}

