import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataCandy } from 'src/provider/datacandy.provider';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'angularAdminLTE';

  constructor(private dataCandy:DataCandy,private router:Router){
    console.log('app reloaded');
    this.dataCandy.printLog('qwrwq','appc');
  }

  ngOnInit(){
    if(localStorage.getItem("loginCredentials")){
      this.dataCandy.loggedin = 1;
      this.dataCandy.loginCredentials =  JSON.parse(atob(localStorage.getItem("loginCredentials")));
      this.dataCandy.loggedInUserId = this.dataCandy.loginCredentials.user_id;
    }else{
      this.router.navigate(['login']);
    }

  }


}
