import { Injectable } from '@angular/core';
// import { Http, Headers, RequestOptions } from '@angular/http';
import { HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Constants } from './constants';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DataCandy } from './datacandy.provider';
import {HttpClient} from "@angular/common/http";
import * as _ from 'lodash';
import {  throwError } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";


declare var androidplugin: any;

export enum HttpMethod {
  GET, POST, PUT, DELETE, GETWITHOUTMAP, POSTWITHOUTMAP
}

@Injectable()
export class HttpProvider {
  public httpHeader: Headers;
  ontIp: string = "";
  constructor(public http:HttpClient,
    private constants: Constants,
    private dataCandy: DataCandy,
    private spinner: NgxSpinnerService
  ) {
    this.httpHeader = new Headers();

  }

  clearHeader() {
    this.httpHeader = new Headers();
  }

  getOptions() {
    // let headers:any ={'content-type':'application/json'}
    // return headers;

    let headers:any = {};
    return headers;


    // if (this.dataCandy.loginCredentials && this.dataCandy.loginCredentials.token) {
    //   let headers:any = {'content-type':'application/json','Access-Control-Allow-Origin':'*',"Authorization": "Bearer " + this.constants.client_secret,"X-API-KEY":this.dataCandy.loginCredentials.token}
    //   return headers;
    // }else{
    //   let headers:any = {'content-type':'application/json','Access-Control-Allow-Origin':'*'}
    //   return headers;
    // }
    
  }


  apiCall(input: any) {
    this.spinner.show();

    this.dataCandy.printLog("apiCall", input);
    
    return Observable.create((observable: any) => {
      switch (input.method) {
        case HttpMethod.GET:
          this.httpHeader.append(this.constants.CONTENT_TYPE, this.constants.APPLICATION_URL_ENCODEDED);
          this.http.get(this.constants.API_URL + '/' + input.relativeUrl+this.jsonToQueryString(input.body), {'headers':this.getOptions()})
          .subscribe((res: any) => {
            this.spinner.hide();
            observable.next(res);
            observable.complete();
          }, (error: any) => {
            this.spinner.hide();


            let errMsg: any = {};
            if (error['_body']) {
              errMsg = JSON.parse(error['_body']);
            }
            observable.error(errMsg);
          });
          break;
        case HttpMethod.POST:

          this.http.post(this.constants.API_URL + '/' + input.relativeUrl, input.body, {headers:this.getOptions()})
          .subscribe((res: any) => {
            this.spinner.hide();

            this.dataCandy.printLog(res);
            observable.next(res);
            observable.complete();
          }, (error: any) => {
            this.spinner.hide();


            let errMsg: any = {};
            if (error['_body']) {
              errMsg = JSON.parse(error['_body']);
            }
            observable.error(errMsg);
          });
          break;
          case HttpMethod.PUT:

            this.http.put(this.constants.API_URL + '/' + input.relativeUrl, input.body, {headers:this.getOptions()})
            .subscribe((res: any) => {
              this.spinner.hide();

              this.dataCandy.printLog(res);
              observable.next(res);
              observable.complete();
            }, (error: any) => {
              this.spinner.hide();

  
              let errMsg: any = {};
              if (error['_body']) {
                errMsg = JSON.parse(error['_body']);
              }
              observable.error(errMsg);
            });
            break;

        case HttpMethod.DELETE:

          this.http.delete(this.constants.API_URL + '/' + input.relativeUrl,{headers:this.getOptions()})
          .subscribe((res: any) => {
            this.spinner.hide();
            this.dataCandy.printLog(res);
            observable.next(res);
            observable.complete();
          }, (error: any) => {
            this.spinner.hide();
            let errMsg: any = {};
            if (error['_body']) {
              errMsg = JSON.parse(error['_body']);
            }
            observable.error(errMsg);
          });
          break;
        default:
          break;

      }
    });
  }



  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  jsonToQueryString(json){
    return '?' + 
    Object.keys(json).map(function(key) {
        return encodeURIComponent(key) + '=' +
            encodeURIComponent(json[key]);
    }).join('&');

  }

}
