<div class="section section-image section-login">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12 ml-auto mr-auto">
                <div class="card card-register">
                    <h3 class="title">Admin Control Muthu Pharmacy</h3>
                

                    <form [formGroup]="form"  class="register-form ng-untouched ng-pristine ng-valid" novalidate="" (ngSubmit)="onSubmit()">
                        <label>Mobile Number</label>
                        <div class="input-group form-group-no-border">
                            <div class="input-group-prepend"><span class="input-group-text"><i
                                        class="fa fa-mobile"></i></span>
                            </div><input class="form-control" placeholder="Mobile Number" formControlName="mobile" type="number">
                        </div><label>Password</label>
                        <div class="input-group form-group-no-border">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i
                                        class="fa fa-key"></i></span>
                            </div>
                            <input class="form-control" placeholder="Password"  type="password" formControlName="password" >
                        </div>
                        <button type='submit' [disabled]="form.invalid" class="btn btn-danger btn-block btn-round" >
                            <span *ngIf="loading"class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span *ngIf="!loading">Login</span></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>